import React, { useEffect } from "react";
import "./aiSummitHomeScreen.css";
import HomeMain from "../../components/homeMain/HomeMain";
import About from "../../components/about/About";
import WorkShop from "../../components/workshop/WorkShop";
import Flagship from "../../assets/images/flag.png";
import Program from "../../components/program/Program";
import Benefit from "../../components/benefit/Benefit";
import Contact from "../../components/contact/Contact";
import RegisterImg from "../../assets/images/register.png";
import RegisterIcon from "../../assets/images/regster.png";
import Footer from "../../assets/images/Footer.png";
import RegisterSmall from "../../assets/images/register_small.png";
import FooterSmall from "../../assets/images/footer_small.png";
import PanelHeader from "../../assets/images/panelHeader.png";
import Panel from "../../assets/images/panel_small.png";
import mixpanel from "mixpanel-browser";

const AiSummitHomeScreen = () => {
  useEffect(() => {
    mixpanel.track("Website - Ai_Summit");
  }, []);

  const handleRegisterClick = () => {
    window.open("https://pages.razorpay.com/aisummit", "_blank");
  };

  return (
    <div className="aiSummitHomeScreenMainContainer">
      <div style={{ marginBottom: "30px" }}>
        <HomeMain />
      </div>

      <div className="container" style={{ marginTop: "30px" }}>
        <About />
      </div>

      <div className="summit_description_container">
        <div className="welcome_text">WELCOME TO THIS</div>
        <div className="flag_img_container">
          <img src={Flagship} alt="flag_img" className="flag_img" />
        </div>
        <div className="flag_desc_container">
          <div>
            We are excited to host Kerala’s largest AI Summit, bringing
            cutting-edge AI knowledge and innovation to God’s Own Country. This
            premier event is designed to immerse attendees in the rapidly
            evolving world of AI, offering them a chance to gain real-world
            experience and explore the latest advancements in the field.
          </div>
          <div>
            What makes this AI Summit truly exceptional is the remarkable lineup
            of global brands such as Google and Accenture, alongside AICTE
            directors, venture capitalists, industry leaders, AI researchers,
            and some of the top AI companies in the world. This dynamic
            environment will provide attendees with the chance to learn from the
            best and engage in invaluable networking opportunities.
          </div>
          <div>
            Prepare to be inspired, gain in-depth insights, and connect with a
            vibrant community of AI enthusiasts, professionals, and innovators.
            Welcome to the future of AI in Kerala!
          </div>
        </div>
      </div>

      <div className="container" style={{ position: "relative", zIndex: 10 }}>
        <WorkShop />
      </div>

      <Program />

      <div className="panel_container">
        <img
          src={PanelHeader}
          alt="panel_header_img"
          className="panel_header_img"
        />
        <img src={Panel} alt="panel_img" className="panel_img" />
      </div>

      <div className="container" style={{ marginBottom: "50px" }}>
        <Benefit />
      </div>

      <div className="container" style={{ marginBottom: "50px" }}>
        <Contact />
      </div>

      <div
        className="container register_container"
        style={{
          backgroundImage: `url(${RegisterImg})`,
        }}
      >
        <div
          className="register_button_container"
          onClick={handleRegisterClick}
        >
          <img src={RegisterIcon} alt="register_img" className="register_img" />
          <div className="register_button_text">Register Now </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="register_small_container"
          style={{
            backgroundImage: `url(${RegisterSmall})`,
          }}
        >
          <div
            className="register_button_container"
            onClick={handleRegisterClick}
          >
            <img
              src={RegisterIcon}
              alt="register_img"
              className="register_img"
            />
            <div className="register_button_text">Register Now </div>
          </div>
        </div>
      </div>

      <div className="footer_container" style={{ paddingBottom: "50px" }}>
        <img src={Footer} alt="footer_img" className="footer_img" />
      </div>

      <div className="footer_small_container">
        <img src={FooterSmall} alt="footer_img" className="footer_small_img" />
      </div>
    </div>
  );
};

export default AiSummitHomeScreen;
